import './App.css';
import Todo from "./components/todo/Todo"

const App = () => {
  return (
    <div className='App'>
        <Todo />
    </div>
  )
}

export default App